(function () {
  'use strict';

  class ModuleSetSelectorCtrl {
    constructor(ModuleSet, $scope) {
      let vm = this;
      vm.ModuleSet = ModuleSet;
      vm.getModulesSet($scope);
    }
    getModulesSet(scope) {
      let vm = this;
      vm.ModuleSet.moduleSetIndex(scope.search)
        .then((data)=> {
          vm.moduleSets = data.module_sets;
        });
    }
  }

  /**
   * @ngdoc object
   * @name learning.index.controller:ModuleSetSelectorCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('ModuleSetSelectorCtrl', ModuleSetSelectorCtrl);
}());
